import Vue from 'vue';
import { BootstrapVue, BootstrapVueIcons, BIcon } from 'bootstrap-vue';
import FileSelector from 'vue-file-selector';
import App from './App.vue';
import HotReloadGuard from './HotReloadGuard.vue';
import store from './store';
import router from './router';
import './style.scss';

Vue.config.productionTip = false
Vue.use(FileSelector);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.component('BIcon', BIcon);
Vue.component('HotReloadGuard', HotReloadGuard);

new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
