<template>
  <div :style="{
    backgroundColor: 'black',
    width: `${width}px`,
    height: `${height}px`,
    transform: `scale(${scale})`,
  }">
    <div v-for="(slot, key) in slots" :key="key">
      <div :style="{
        position: 'absolute',
        left: `${getX(slot)}`,
        top: `${getY(slot)}`,
      }">
        <div :style="{
          border: '5px dashed white',
          borderRadius: '4px',
          transform: `rotate(${landscape
            ? slot.rotation_landscape
            : slot.rotation}deg)`,
          margin: `${-size*scale/2 + 2.5}px 0 0 ${-size*scale/2 + 2.5}px`,
          height: `${h}px`,
          width: `${w}px`,
          color: 'red',
          fontSize: '30px',
          fontWeight: 'bold',
        }">
          {{ key }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    groups: { type: Array, required: true },
    slots: { type: Object, required: true },
    width: Number,
    height: Number,
    scale: Number,
  },
  data: () => ({
    size: 100,
  }),
  computed: {
    landscape() {
      return this.width > this.height
    },
    w() {
      return this.size * this.scale * 0.7333;
    },
    h() {
      return this.size * this.scale;
    },
  },
  methods: {
    getGroup(group) {
      return this.groups.find(({ name }) => name === group);
    },
    getX({ group, x, x_landscape }) {
      if (this.landscape) {
        // let groupWidth = 300;
        return `calc(${
          this.getGroup(group).x_landscape
        }% + ${x_landscape * this.scale}px`;
      } else {
        // let groupWidth = 300;
        return `calc(${
          this.getGroup(group).x
        }% + ${x * this.scale}px`;
      }
    },
    getY({ group, y, y_landscape }) {
      if (this.landscape) {
        // let groupHeight = 300 * 0.7333;
        return `calc(${
          this.getGroup(group).y_landscape
        }% + ${y_landscape * this.scale}px`;
      } else {
        // let groupHeight = 300 * 0.7333;
        return `calc(${
          this.getGroup(group).y
        }% + ${y * this.scale}px`;
      }
    },
  },
};
</script>
