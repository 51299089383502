<template>
  <div :style="{
    backgroundColor: 'black',
    width: `${width}px`,
    height: `${height}px`,
    transform: `scale(${scale})`,
  }">
    <div v-for="(group, i) in groups" :key="i">
      <div :style="{
        position: 'absolute',
        left: `${(landscape ? group.x_landscape : group.x)}%`,
        top: `${(landscape ? group.y_landscape : group.y)}%`,
      }">
        <div :style="{
          border: '5px dashed white',
          borderRadius: '4px',
          margin: `${-size*scale/2 + 2.5}px 0 0 ${-size*scale/2 + 2.5}px`,
          height: `${h}px`,
          width: `${w}px`,
          color: 'red',
          fontSize: '30px',
          fontWeight: 'bold',
        }">
          {{ i }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    groups: { type: Array, required: true },
    width: Number,
    height: Number,
    scale: Number,
  },
  data: () => ({
    size: 200,
  }),
  computed: {
    landscape() {
      return this.width > this.height
    },
    w() {
      return this.size * this.scale;
    },
    h() {
      return this.size * this.scale * 0.7333;
    },
  },
};
</script>
