import Vue from 'vue';
import Vuex from 'vuex';
import { images } from '@/storage';

Vue.use(Vuex);

async function storagePathToBase64(path) {
  const url = await images.child(path).getDownloadURL();
  const xhr = new XMLHttpRequest();
  return new Promise((resolve, reject) => {
    xhr.responseType = 'blob';
    xhr.onload = () => {
      const reader = new FileReader();
      reader.readAsDataURL(xhr.response);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    };
    xhr.open('GET', url);
    xhr.send();
  });
}

export default new Vuex.Store({
  state: {
    images: {},
  },
  actions: {
    async cacheImage({ commit, state: { images } }, path) {
      if (images[path] !== undefined) {
        return images[path];
      }
      const data = await storagePathToBase64(path).catch(console.error);
      commit('CACHE_IMAGE', { path, data });
    },
  },
  mutations: {
    CACHE_IMAGE(state, { path, data }) {
      state.images = { [path]: data, ...state.images };
    },
  },
});
