<template>
  <div id="app">
    <b-navbar toggleable="lg" type="dark" variant="dark">
      <b-navbar-brand href="/">
        <img
          src="/img/icon.png"
          class="d-inline-block align-top rounded-circle mr-3"
          height="30"
          alt="Cards Logo"
        />
        <img
          src="/img/logo.png"
          class="d-inline-block align-top"
          height="30"
          alt="Mythulu Creation Cards"
        />
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>

        <b-navbar-nav v-if="user">
          <b-nav-item :to="{ name: 'layouts' }"
            :active="$route.name === 'layouts'"
          >Layouts</b-nav-item>
          <!-- <b-nav-item :to="{ name: 'decks' }"
            :active="$route.name === 'decks'"
          >Decks</b-nav-item>
          <b-nav-item :to="{ name: 'draw' }"
            :active="$route.name === 'draw'"
          >Draw</b-nav-item> -->
        </b-navbar-nav>

        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown right v-if="user">
            <template #button-content>
              <img :src="user.providerData[0].photoURL" height="30"
                class="rounded border border-light mr-2"
                alt="User image"
              />
            </template>

            <!-- <b-dropdown-item href="#">Profile</b-dropdown-item> -->
            <b-dropdown-item @click="signOut">Sign Out</b-dropdown-item>

          </b-nav-item-dropdown>
          <b-button v-else variant="primary" @click="signIn">Log in</b-button>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <div class="mt-2" v-if="isAdmin">
      <router-view></router-view>
    </div>
    <div v-else>
      Admins only!
    </div>

    <div style="min-height: 400px;"></div>
  </div>
</template>

<script>
import { auth, providers } from '@/firebase';

export default {
  name: 'App',
  data: () => ({
    user: null,
    isAdmin: false,
  }),
  methods: {
    signIn() {
      auth.signInWithPopup(providers.google).catch(console.error);
    },
    signOut() {
      auth.signOut();
    },
  },
  created() {
    auth.onAuthStateChanged((user) => {
      this.user = user;
      this.isAdmin = false;
      if (user) {
        user.getIdTokenResult().then(
          (idTokenResult) => this.isAdmin = !!idTokenResult.claims.isAdmin
        );
      }
    });
  },
}
</script>
