var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({
  backgroundColor: 'black',
  width: (_vm.width + "px"),
  height: (_vm.height + "px"),
  transform: ("scale(" + _vm.scale + ")"),
})},_vm._l((_vm.slots),function(slot,key){return _c('div',{key:key},[_c('div',{style:({
      position: 'absolute',
      left: ("" + (_vm.getX(slot))),
      top: ("" + (_vm.getY(slot))),
    })},[_c('div',{style:({
        border: '5px dashed white',
        borderRadius: '4px',
        transform: ("rotate(" + (_vm.landscape
          ? slot.rotation_landscape
          : slot.rotation) + "deg)"),
        margin: ((-_vm.size*_vm.scale/2 + 2.5) + "px 0 0 " + (-_vm.size*_vm.scale/2 + 2.5) + "px"),
        height: (_vm.h + "px"),
        width: (_vm.w + "px"),
        color: 'red',
        fontSize: '30px',
        fontWeight: 'bold',
      })},[_vm._v(" "+_vm._s(key)+" ")])])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }