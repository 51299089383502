import Vue from 'vue';
import VueRouter from 'vue-router';
// import ViewDecks from '@/components/ViewDecks.vue';
// import ViewDraw from '@/components/ViewDraw.vue';
import ManageLayouts from '@/components/ManageLayouts.vue';
import ManageLayout from '@/components/ManageLayout.vue';
// import Deck from '@/components/Deck.vue';

Vue.use(VueRouter);

export default new VueRouter({
  routes: [
    // { path: '/decks', name: 'decks', component: ViewDecks, children: [
    //   { path: ':id', name: 'deck', component: Deck, props: true },
    // ]},
    // { path: '/draw', name: 'draw', component: ViewDraw },
    { path: '/layouts', name: 'layouts', component: ManageLayouts, children: [
      { path: ':id', name: 'layout', component: ManageLayout, props: true },
    ]},
  ],
});
