<template>
  <HotReloadGuard
    :guard-if="!Object.entries(doc).length"
    @reload="_getDoc(id)"
  >
    <b-row>
      <b-col cols="6">
        <b-input
          v-model="doc.title"
          :autofocus="!doc.title"
          :state="(doc.title || '').length === 0 ? false : null"
          size="lg"
          class="p-0 m-0 border-top-0 border-right-0 border-left-0"
          style="font-size: 2em;"
          placeholder="Name"
          @change="dirty = true"
        />
      </b-col>
      <b-col class="text-right">
        <b-button @click="duplicate" variant="outline-secondary" size="sm" class="mr-3">
          <b-icon icon="files" />
          Duplicate
        </b-button>
        <b-button @click="destroy" variant="danger" size="sm" class="mr-3">
          <b-icon icon="trash" />
          Delete
        </b-button>
        <b-button
          :disabled="!dirty || !saveFinished"
          :variant="
            saveError
            ? 'danger'
            : dirty
            ? 'warning'
            : saveFinished
            ? 'success'
            : 'outline-secondary'
          "
          size="sm"
          @click="update"
        >
          <b-icon :icon="
            !saveFinished
            ? 'hourglass-split'
            : saveError
            ? 'exclamation-triangle-fill'
            : dirty
            ? 'cloud-upload'
            : saveFinished
            ? 'check'
            : 'cloud-upload'
          " />
          {{
            saveError ? 'ERROR' : dirty ? 'Save' : saveFinished ? 'Saved' : '?'
          }}
        </b-button>
      </b-col>
    </b-row>
    <p class="text-muted">ID: {{ id }}</p>
    <b-row>
      <b-col cols="5">
        <h6 class="text-center">Settings</h6>
        <LayoutInfo
          :doc="doc"
          @save="update($event)"
          @release="release"
          @delete="destroy"
        />
      </b-col>
      <b-col>
        <h6 class="text-center">Description</h6>
        <b-textarea
          v-model="doc.description"
          placeholder="Layout description"
          rows="3"
          max-rows="3"
          @change="dirty = true"
        />
        Related Layouts
        <b-form-input v-model="doc.related1" trim
          @change="dirty = true"
        />
        <b-form-input v-model="doc.related2" trim
          @change="dirty = true"
        />
        <b-form-input v-model="doc.related3" trim
          @change="dirty = true"
        />
        <b-form-input v-model="doc.related4" trim
          @change="dirty = true"
        />
      </b-col>
    </b-row>

    <hr />

    <h4>
      Slot Groups
      <b-button variant="link" size="sm" @click="newGroup()">
        <b-icon icon="plus-circle" />
        New
      </b-button>
      <b-form-checkbox size="sm"
        v-model="showGroupLayout"
      >
        Show Layout Preview
      </b-form-checkbox>
    </h4>

    <b-row v-if="showGroupLayout" style="position: absolute; left: -0px">
      <b-col md="auto" class="text-center">
        <PreviewGroupLayout
          :groups="groups.map(group => Object.assign({
            rotation: 0,
            landscape_rotation: 0
          }, group))"
          :width="207"
          :height="289"
          :scale="0.5"
        />
      </b-col>
    </b-row>

    <b-row v-if="showGroupLayout" style="position: absolute; left: -50px; margin-top: 289px">
      <b-col md="auto" class="text-center">
        <PreviewGroupLayout
          :groups="groups.map(group => Object.assign({
            rotation: 0,
            landscape_rotation: 0
          }, group))"
          :width="400"
          :height="93"
          :scale="0.5"
        />
      </b-col>
    </b-row>
    <b-row v-for="(group, i) in groups" :key="i">
      <b-col offset="2" cols="2">
        <b-form-row>
          <b-col>
            <b-form-group label="Name" :label-for="`${i}-name`">
              <b-form-input size="sm" :id="`${i}-name`"
                @change="dirty = true"
                v-model="group.name"
              />
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-checkbox switch :id="`${i}-ordered`"
              @change="dirty = true"
              v-model="group.ordered"
            >Recommend order</b-checkbox>
          </b-col>
        </b-form-row>
      </b-col>
      <b-col cols="2">
        <b-form-row>
          <b-col cols="6">
            <b-form-group
              label="P X %"
              :label-for="`${i}-coords-x`"
            >
              <b-form-input size="sm" :id="`${i}-coords-x`"
                @change="dirty = true"
                v-model="group.x"
                type="number"
                number
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="P Y %"
              :label-for="`${i}-coords-x`"
            >
              <b-form-input size="sm" :id="`${i}-coords-x`"
                @change="dirty = true"
                v-model="group.y"
                type="number"
                number
              />
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col cols="6">
            <b-form-group
              label="L X %"
              :label-for="`${i}-coords-x-landscape`"
            >
              <b-form-input size="sm" :id="`${i}-coords-x-landscape`"
                @change="dirty = true"
                v-model="group.x_landscape"
                type="number"
                number
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="L Y %"
              :label-for="`${i}-coords-x-landscape`"
            >
              <b-form-input size="sm" :id="`${i}-coords-x-landscape`"
                @change="dirty = true"
                v-model="group.y_landscape"
                type="number"
                number
              />
            </b-form-group>
          </b-col>
        </b-form-row>
      </b-col>
      <b-col>
        <b-form-group label="Description" :label-for="`${i}-desc`">
          <b-form-input size="sm" :id="`${i}-desc`"
            @change="dirty = true"
            v-model="group.description" class="w-100"
          />
        </b-form-group>
      </b-col>
      <b-col cols="1">
      <b-button variant="danger" size="sm" @click="destroyGroup(i)">
        <b-icon icon="trash" />
      </b-button>
      </b-col>
    </b-row>

    <hr/>

    <h4>
      Pages
      <b-button variant="link" size="sm" @click="newPage({})">
        <b-icon icon="plus-circle" />
        New
      </b-button>
    </h4>
    <b-table
      :items="showPage ? pages : []"
      :fields="['index', 'name', 'description', 'actions']"
      striped
      hover
    >
      <template #cell(index)="{ index }">
        #{{ index + 1 }}
      </template>

      <template #cell(name)="{ item }">
        <b-form-input v-model="item.name" @change="dirty = true"
          placeholder="Page name" />
      </template>

      <template #cell(description)="{ item }">
        <b-textarea v-model="item.description" @change="dirty = true"
          placeholder="Page description" />
      </template>

      <template #cell(actions)="{ detailsShowing, toggleDetails, index }">
        <b-button
          variant="outline-secondary"
          size="sm"
          @click="toggleDetails"
          class="mr-2"
        >
          {{ detailsShowing ? 'Hide' : 'Edit' }}
        </b-button>
        <b-button
          variant="danger"
          size="sm"
          @click="destroyPage(index)"
          class="mr-2"
        >
          <b-icon icon="trash" />
          Page
        </b-button>
        <b-button
          variant="outline-primary"
          size="sm"
          @click="newSlot(index)"
          class="mr-2"
        >
          <b-icon icon="plus-circle" />
          Slot
        </b-button>
      </template>

      <template #row-details="{ item, index }">
        <div class="text-right">
          <b-button
            :disabled="!dirty || !saveFinished"
            :variant="
              saveError
              ? 'danger'
              : dirty
              ? 'warning'
              : saveFinished
              ? 'success'
              : 'outline-secondary'
            "
            size="sm"
            @click="update"
          >
            <b-icon :icon="
              !saveFinished
              ? 'hourglass-split'
              : saveError
              ? 'exclamation-triangle-fill'
              : dirty
              ? 'cloud-upload'
              : saveFinished
              ? 'check'
              : 'cloud-upload'
            " />
            {{
              saveError ? 'ERROR' : dirty ? 'Save' : saveFinished ? 'Saved' : '?'
            }}
          </b-button>
        </div>
        <b-row v-if="showEditor">
          <b-col offset="2">
            <b-row style="position: absolute; left: -200px">
              <b-col md="auto" class="text-center">
                <PreviewLayout
                  :groups="groups"
                  :slots="item.slots"
                  :width="207"
                  :height="289"
                  :scale="0.5"
                />
              </b-col>
            </b-row>
            <b-row style="position: absolute; left: -300px; margin-top: 289px">
              <b-col md="auto" class="text-center">
                <PreviewLayout
                  :groups="groups"
                  :slots="item.slots"
                  :width="400"
                  :height="93"
                  :scale="0.5"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col xl="2" lg="3" md="3" sm="4" v-for="(slot, key) in item.slots" :key="key">
                <JsonEditor
                  :json="item.slots[key]"
                  :slotKey="key"
                  :groups="groups"
                  @dirty="dirty = true"
                  @destroy="destroySlot(index, key)"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </template>

    </b-table>
  </HotReloadGuard>
</template>

<script>
import { get, set } from 'lodash';
import { v4 as uuid } from 'uuid';
import Vue from 'vue';
import PreviewLayout from './PreviewLayout.vue';
import PreviewGroupLayout from './PreviewGroupLayout.vue';
import JsonEditor from './JsonEditor.vue';
import LayoutInfo from './LayoutInfo.vue';
import { layouts_mythulu } from '@/collections';

export default {
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      await vm._getDoc(to.params.id);
    });
  },
  async beforeRouteUpdate(to, from, next) {
    await this._getDoc(to.params.id);
    next();
  },
  props: {
    id: String,
  },
  data: () => ({
    showEditor: true,
    showPage: true,
    showGroupLayout: true,
    doc: {},
    pages: [],
    groups: [],
    itemType: null,
    itemPath: null,
    itemValue: null,
    keyMode: false,
    arrayMode: false,
    dirty: false,
    saveFinished: true,
    saveError: false,
  }),
  methods: {
    _getDoc(id) {
      this.$doc = layouts_mythulu.doc(id);
      return this.$doc.get().then((ref) => {
        if (ref.exists) {
          Vue.set(this, 'doc', ref.data());
        }
      }).catch(console.error);
    },
    refreshEditor() {
      this.showEditor = false;
      this.$nextTick(() => {
        this.showEditor = true;
      });
    },
    refreshPages() {
      this.showPage = false;
      this.$nextTick(() => {
        this.showPage = true;
      });
    },
    getSlots(page) {
      return this.pages[page].slots;
    },
    usedSlots() {
      const keys = [].concat(...this.doc.pages.map(
        ({ slots }) => Object.keys(slots)
      ));
      keys.sort((a, b) => Number(a) - Number(b));
      return keys;
    },
    nextSlot() {
      let nextSlot = null;
      const used = this.usedSlots().map(Number);
      for (let n = 0; n < used.length; n++) {
        if (n === 0 && !used.includes(n)) {
          nextSlot = 0;
        } else if (used[n] - used[n - 1] > 1) {
          nextSlot = n;
          break;
        }
      }
      if (nextSlot === null) {
        nextSlot = Math.max(...used) + 1 || 0;
      }
      if (nextSlot === Infinity) {
        nextSlot = 0;
      }
      return nextSlot;
    },
    edit(basePath, path, value) {
      if (basePath === path) {
        return;
      }
      this.arrayMode = ['[', ']'].includes(value);
      this.keyMode = ['{', '}'].includes(value);
      this.itemType = typeof value === 'number' ? 'number' : 'text'
      this.itemPath = path;
      this.itemValue = this.arrayMode
        ? get(this.doc, path).join(', ')
        : this.keyMode
        ? path.split('.').slice(-1)[0]
        : value
        ;
      this.$bvModal.show('value-editor');
    },
    updateField(event) {
      if (this.keyMode) {
        if (this.usedSlots().includes(this.itemValue)) {
          event.preventDefault();
          alert(`${this.itemValue} is already used.`);
          return;
        }
        const prefix = this.itemPath.split('.').slice(0, -1).join('.');
        const root = get(this.doc, prefix);
        const newPath = `${prefix}.${this.itemValue}`;
        set(this.doc, newPath, get(this.doc, this.itemPath));
        set(this.doc, this.itemPath, get(this.doc, this.itemPath));
        delete root[this.itemPath.split('.').slice(-1)[0]];
      } else {
        set(this.doc, this.itemPath, this.arrayMode
          ? this.itemValue.split(/\s*,\s*/)
          : this.itemValue);
      }
      this.keyMode = false;
      this.arrayMode = false;
      this.itemType = null;
      this.itemPath = null;
      this.itemValue = null;
      this.dirty = true;
    },
    update(data=undefined) {
      console.log('saving...');
      Object.assign(this.doc, data || {});
      this.saveFinished = false;
      this.saveError = false;
      return this.$doc.set(this.doc).then(() => {
        console.log('done');
        this.dirty = false;
        this.saveFinished = true;
      }).catch((e) => {
        console.error('error', e);
        this.saveError = true;
        this.saveFinished = true;
      });
    },
    release() {
      return this.update({ is_released: true });
    },
    async duplicate() {
      const id = uuid();
      await layouts_mythulu.doc(id).set(Object.assign({}, this.doc, {
        title: `${this.doc.title} (copy)`,
      }));
      this.$router.push({ name: 'layout', params: { id } });
    },
    destroy() {
      this.$doc.delete();
      this.$router.push({ name: 'layouts' });
    },
    destroyGroup(i) {
      this.groups.splice(i, 1);
      this.doc.groups = this.groups;
      this.dirty = true;
      // this.refreshPages();
    },
    destroyPage(i) {
      this.pages.splice(i, 1);
      this.doc.pages = this.pages;
      this.dirty = true;
      this.refreshPages();
    },
    destroySlot(i, key) {
      delete this.pages[i].slots[key];
      Vue.set(this.pages[i], 'slots', this.pages[i].slots);
      this.dirty = true;
      this.refreshEditor();
    },
    newGroup() {
      this.groups.push({
        name: '',
        description: '',
        ordered: true,
        x: 0,
        x_landscape: 0,
        y: 0,
        y_landscape: 0,
      });
    },
    newPage(data) {
      this.pages.push(Object.assign(
        {
          name: '',
          description: '',
          slots: {},
        },
        data || {}
      ));
      this.dirty = true;
      this.refreshPages();
    },
    newSlot(page, data) {
      Object.assign(
        this.pages[page].slots,
        { [`${this.nextSlot()}`]: {
            name: '',
            description: '',
            deck_group: null,
            decks_prefer: [],
            decks_avoid: [],
            layer: 1,
            x: 0,
            x_landscape: 0,
            y: 0,
            y_landscape: 0,
            rotation: 0,
            rotation_landscape: 0,
            ...(data || {}),
          }},
      );
      this.dirty = true;
      this.refreshEditor();
    }
  },
  updated() {
    this.pages = this.doc.pages;
    if (this.doc.groups === undefined) {
      this.doc.groups = [];
    }
    this.groups = this.doc.groups;
  },
  components: {
    LayoutInfo,
    JsonEditor,
    PreviewLayout,
    PreviewGroupLayout,
  },
}
</script>
