<template>
  <div>
    <b-card>
      <b-row>
        <b-col>
          <b-form-group
            label="Store Category"
            label-for="category"
          >
            <b-form-input
              size="sm"
              id="category"
              v-model="doc.category"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Collection Name"
            label-for="collection"
          >
            <b-form-input
              size="sm"
              id="collection"
              v-model="doc.collection"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Part"
            label-for="part"
          >
            <b-form-input
              size="sm"
              id="part"
              v-model="doc.collection_part"
              type="number"
              number
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Total Parts"
            label-for="parts"
          >
            <b-form-input
              size="sm"
              id="parts"
              v-model="doc.collection_parts"
              type="number"
              number
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-checkbox switch v-model="doc.tablet_only"
            class="mt-1"
          >Tablet-only</b-checkbox>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <b-checkbox switch v-model="doc.is_paid"
            class="mt-1"
          >Paid</b-checkbox>
        </b-col>
        <b-col>
          <b-input-group append="coins" size="sm">
            <b-form-input v-model="doc.cost"
              :disabled="!doc.is_paid"
              type="number"
              number
            ></b-form-input>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <b-checkbox switch v-model="doc.is_discount"
            class="mt-1"
          >Discounted</b-checkbox>
        </b-col>
        <b-col>
          <b-input-group append="coins" size="sm">
            <b-form-input v-model="doc.discount"
              :disabled="!doc.is_discount"
              type="number"
              number
            ></b-form-input>
          </b-input-group>
        </b-col>
      </b-row>
      <template #footer>
        <b-button
          @click="$emit('save')"
          variant="primary"
          size="sm"
          class="mr-1"
        >Save</b-button>
        <b-button v-if="!doc.is_released"
          v-b-modal.confirm-release
          variant="warning"
          size="sm"
          class="mx-1"
        >Release</b-button>
        <!-- <b-button v-if="!doc.is_released"
          v-b-modal.confirm-delete
          variant="outline-danger"
          size="sm"
          class="mx-1"
        >Delete</b-button> -->
      </template>
    </b-card>
    <b-modal id="confirm-release"
      :title="`Release '${doc.title}'`"
      @ok="$emit('release')"
    >
      This will be permanent, although you can still update layout information
      at any time.
    </b-modal>
    <!-- <b-modal id="confirm-delete"
      :title="`Delete '${doc.title}'`"
      @ok="$emit('delete')"
    >
      This will be permanent.
    </b-modal> -->
  </div>
</template>

<script>
export default {
  props: {
    doc: Object,
  },
}
</script>
