import Vue from 'vue';
import VueFirestore from 'vue-firestore';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyBJV7r3xMxkq-DIkTpQGY7X4g_Th1sT58Q",
  authDomain: "mythulu-app.firebaseapp.com",
  projectId: "mythulu-app",
  storageBucket: "mythulu-app.appspot.com",
  messagingSenderId: "37323338207",
  appId: "1:37323338207:web:e5463dc42443556375546a",
  measurementId: "G-L3W13V1GF7"
};
firebase.initializeApp(firebaseConfig);

Vue.use(VueFirestore, {
  key: 'id',
});

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storage = firebase.storage();

export const providers = {
  google: new firebase.auth.GoogleAuthProvider(),
};
// export const collections = {
//   decks: firestore.collection('decks'),
//   layouts_mythulu: firestore.collection('layouts_mythulu'),
// };
