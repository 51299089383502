<template>
  <div>
    <b-row>
      <b-col cols="2">
        <h5 class="text-center" style="margin-top: 21px;">
          Layouts by Mythulu
        </h5>
        <b-list-group>

          <b-list-group-item
            v-for="(layout, id) in layouts_mythulu"
            :key="id"
            :to="{ name: 'layout', params: { id } }"
            :active="$route.params.id === id"
            class="d-flex align-items-center"
          >
            [{{ layout.category }}]
            {{ layout.collection }}:
            {{ layout.title || '(Untitled)' }}
            <span class="ml-auto"/>
            <b-icon v-if="!layout.is_released"
              title="Unreleased"
              icon="eye-slash"
              class="mr-1"
              :variant="$route.params.id === id ? 'light' : 'dark'"
            />
            <b-badge variant="light">
              {{ Object.values(layout.groups || []).length }}
            </b-badge>
          </b-list-group-item>

          <b-list-group-item @click.prevent="create({})" href="#">
            <b-icon icon="plus-circle-fill"/>
            New
          </b-list-group-item>

        </b-list-group>
      </b-col>
      <b-col>
        <router-view></router-view>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { v4 as uuid } from 'uuid';
import { layouts_mythulu } from '@/collections';

export default {
  firestore: () => ({
    layouts_mythulu: {
      ref: layouts_mythulu.orderBy('category'),
      objects: true,
      reject: console.error,
    }
  }),
  methods: {
    create(data) {
      const id = uuid();
      layouts_mythulu.doc(id).set(Object.assign(
        {
          title: null,
          description: '',

          category: 'unknown',
          type: 'unknown',
          collection: 'unknown',
          collection_part: null,
          collection_parts: null,
          is_released: false,
          is_paid: false,
          is_discount: false,
          price: 0,
          discount: 0,

          pages: [],
          groups: [],
          related1: null,
          related2: null,
          related3: null,
          related4: null,
        },
        data || {},
      ));
      this.$router.push({ name: 'layout', params: { id } });
    },
  },
}
</script>
