<template>
  <div style="min-height: 200px;">
    <slot name="needs-reload" v-if="guardIf">
      <div class="text-center">
        <b-button @click="$emit('reload')">
          <b-icon icon="arrow-repeat"></b-icon>
        </b-button>
      </div>
    </slot>
    <slot v-else></slot>
  </div>
</template>

<script>

export default {
  name: 'HotReloadGuard',
  props: {
    guardIf: Boolean,
  },
}
</script>
