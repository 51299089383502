<template>
  <div>
    <h5>
      <code
        class="p-2"
        style="background-color: rgba(239, 176, 204, 0.4)"
      >{{ slotKey }}</code>
      <b-select
        size="sm"
        v-model="json.layer"
        style="width: auto;"
        @change="$emit('dirty')"
        :options="[
          { text: 'Layer 1', value: 1 },
          { text: 'Layer 2', value: 2 },
          { text: 'Layer 3', value: 3 },
          { text: 'Layer 4', value: 4 },
        ]"
      />
      <b-button
        variant="outline-danger"
        size="sm"
        @click="confirm(`Destroy [${slotKey}] '${json.name}'?`)
          && $emit('destroy')"
        class="float-right"
      >
        <b-icon icon="trash" />
      </b-button>
    </h5>
    <b-form-group
      label-cols="4"
      label="Group"
      :label-for="`${slotKey}-group`"
    >
      <b-select
        @change="$emit('dirty')"
        size="sm"
        :id="`${slotKey}-group`"
        v-model="json.group"
        :options="[{ text: '(None)', value: null }].concat(
          groups.map(({ name }) => ({ text: name, value: name }))
        )"
      />
    </b-form-group>
    <b-form-input
      @change="$emit('dirty')"
      placeholder="Slot Name"
      size="sm"
      :id="`${slotKey}-name`"
      v-model="json.name" trim />
    <b-form-textarea
      @change="$emit('dirty')"
      placeholder="Slot Description"
      size="sm"
      :id="`${slotKey}-description`"
      v-model="json.description" trim />
    <b-form-row>
      <b-col md="6">
        <b-form-group
          label-cols="3"
          label="PX"
          :label-for="`${slotKey}-x`"
        >
          <b-form-input
            @change="$emit('dirty')"
            size="sm"
            :id="`${slotKey}-x`"
            v-model="json.x"
            type="number" number />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label-cols="3"
          label="PY"
          :label-for="`${slotKey}-y`"
        >
          <b-form-input
            @change="$emit('dirty')"
            size="sm"
            :id="`${slotKey}-y`"
            v-model="json.y"
            type="number" number />
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col md="9">
        <b-form-group
          label-cols="6"
          label="P Rotation"
          :label-for="`${slotKey}-rotation`"
        >
          <b-form-input
            @change="$emit('dirty')"
            size="sm"
            :id="`${slotKey}-rotation`"
            v-model="json.rotation"
            type="number" number />
        </b-form-group>
      </b-col>
      <b-col>
        <div :style="{
          display: 'inline-block',
          border: '2px dashed black',
          borderRadius: '4px',
          transform: `rotate(${json.rotation}deg)`,
          height: '50px',
          width: `${50 * 0.7333}px`,
        }" />
      </b-col>
    </b-form-row>
    <!-- <strong>Landscape:</strong> -->
    <b-form-row>
      <b-col md="6">
        <b-form-group
          label-cols="3"
          label="LX"
          :label-for="`${slotKey}-x_landscape`"
        >
          <b-form-input
            @change="$emit('dirty')"
            size="sm"
            :id="`${slotKey}-x_landscape`"
            v-model="json.x_landscape"
            type="number" number />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label-cols="3"
          label="LY"
          :label-for="`${slotKey}-y_landscape`"
        >
          <b-form-input
            @change="$emit('dirty')"
            size="sm"
            :id="`${slotKey}-y_landscape`"
            v-model="json.y_landscape"
            type="number" number />
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col md="9">
        <b-form-group
          label-cols="6"
          label="L Rotation"
          :label-for="`${slotKey}-rotation_landscape`"
        >
          <b-form-input
            @change="$emit('dirty')"
            size="sm"
            :id="`${slotKey}-rotation_landscape`"
            v-model="json.rotation_landscape"
            type="number" number />
        </b-form-group>
      </b-col>
      <b-col>
        <div :style="{
          display: 'inline-block',
          border: '2px dashed black',
          borderRadius: '4px',
          transform: `rotate(${json.rotation_landscape}deg)`,
          height: '50px',
          width: `${50 * 0.7333}px`,
        }" />
      </b-col>
    </b-form-row>
    <b-form-group
      label="Deck List"
      :label-for="`${slotKey}-deck_group`"
    >
      <b-form-select :id="`${slotKey}-deck_group`"
        @change="$emit('dirty')"
        v-model="json.deck_group"
        :options="[null, 'Starter Pack', 'Boosters'].map(value => ({
          value, text: value || 'Any',
        }))"
        size="sm"
      />
    </b-form-group>
    <b-row no-gutters>
      <b-col cols="6">
        <b-form-group
          label="Deck whitelist"
          :label-for="`${slotKey}-decks_prefer`"
        >
          <b-button size="sm" @click="showWhitelist = !showWhitelist">Toggle</b-button>
          <div><code class="small">{{ json.decks_prefer }}</code></div>
          <div v-show="showWhitelist">
            <b-form-checkbox-group :id="`${slotKey}-decks_prefer`"
              @change="$emit('dirty')"
              v-model="json.decks_prefer"
              :options="[
                'Elements',
                'Habitats',
                'Characters',
                'Relationships',
                'Traits',
                'Textures',
                'Dialogue',
                'Travel',
              ].map(v => ({ text: v, value: v }))"
              size="sm"
            />
          </div>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          label="Deck blacklist"
          :label-for="`${slotKey}-decks_avoid`"
        >
          <b-button size="sm" @click="showBlacklist = !showBlacklist">Toggle</b-button>
          <div><code class="small">{{ json.decks_avoid }}</code></div>
          <div v-show="showBlacklist">
            <b-form-checkbox-group :id="`${slotKey}-decks_avoid`"
              @change="$emit('dirty')"
              v-model="json.decks_avoid"
              :options="[
                'Elements',
                'Habitats',
                'Characters',
                'Relationships',
                'Traits',
                'Textures',
                'Dialogue',
                'Travel',
              ].map(v => ({ text: v, value: v }))"
              size="sm"
            />
          </div>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    slotKey: { type: String, required: true },
    json: { type: Object, required: true },
    groups: { type: Array, required: true },
  },
  data: () => ({
    showWhitelist: false,
    showBlacklist: false,
  }),
  methods: {
    confirm(msg) {
      return window.confirm(msg);
    }
  },
};
</script>
